import axios from 'axios'

export const account_service = {

  async get_accounts(data) {
    return axios.post(`accounts-all`, data)
  },

  async search_accounts(data) {
    return axios.post(`search-acount`, data)
  },

  async get_account(id) {
    return axios.get(`account/${id}`)
  },

  async update_account(data) {
    return axios.put(`account/token`, data)
  },

  async update_account_category(data) {
    return axios.post(`account-category`, data)
  },

  async get_account_category() {
    return axios.post(`account-category-search`)
  }

}