<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Buscar usuarios</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-row dense>
          <v-col cols="12" md="3">
            <v-text-field v-model="search.search" outlined small dense label="Buscar"></v-text-field>
          </v-col>
        </v-row>

        <loader v-if="isloading"></loader>



        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.status="{item}">
                <v-chip :color="get_color(item.status)" small dense class="white--text">{{item.status}}</v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>


  </v-container>
</template>

<script>
  import {
    account_service
  } from '~/account_service'

  import debounce from 'lodash/debounce'

  export default {
    data() {
      return {
        colors: {
          PENDING: 'orange',
          ACTIVE: 'green',
          CANCELLED: 'red lighten-1'
        },
        search: {
          search: ""
        },

        items: [],

        headers: [{
            text: 'ID',
            value: 'id',
          }, {
            text: 'nombre',
            value: 'name',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Plan',
            value: 'status',
          }
        ]
      }
    },

    watch: {
      'search': {
        deep: true,
        immediate: true,
        handler(n) {
          this.debounceQuery(n)
        }
      }
    },

    methods: {
      debounceQuery: debounce(function(query) {
        this.searchAccount(query)
      }, 800),

      searchAccount(data) {
        account_service.search_accounts(data).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },

      get_color(status) {
        return this.colors[status]
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>