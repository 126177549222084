import VueRouter from 'vue-router'
import auth from '../auth/auth.js'

import Inicio from '../base_components/inicio/Inicio.vue'
import Login from '../base_components/login/Login.vue'
import Registro from '../base_components/registro/Registro.vue'
import Recover from '../base_components/registro/Recover.vue'


import categorias from '../modulos/categorias/rutas/rutas_categorias.js'
import servicios from '../modulos/servicios/rutas/rutas_servicios.js'
import pack from '../modulos/pack/rutas/rutas_pack.js'
import booking from '../modulos/booking/rutas/rutas_booking.js'
import paquetes from '../modulos/paquetes/rutas/rutas_paquetes.js'
import ucsis from '../modulos/ucsis/rutas/rutas_ucsis.js'
import post from '../modulos/post/rutas/rutas_post.js'
import admin_post from '../modulos/admin_post/rutas/rutas_post.js'
import location from '../modulos/location/rutas/rutas_location.js'
import reportes from '../modulos/reportes/rutas/rutas_reportes.js'
import chat from '../modulos/chat/rutas/rutas_chat.js'
import productos from '../modulos/productos/rutas/rutas_productos.js'
import adverts from '../modulos/adverts/rutas/rutas_adverts.js'
import ticket from '../modulos/ticket/rutas/rutas_ticket.js'
import lead from '../modulos/leads/rutas/rutas_lead.js'
import directorio from '../modulos/directorio/rutas/rutas_directorio.js'
import perfil from '../modulos/perfil/rutas/rutas_perfil.js'
import fblead from '../modulos/fbleads/rutas/rutas_fblead.js'
import costo from '../modulos/costo_vida/rutas/rutas_costo_vida.js'
import account from '../modulos/account/rutas/rutas_account.js'

const routes = [
  ...route('/login', Login),
  ...route('/registro', Registro),
  ...route('/recuperar-password', Recover),
  ...route('/', Inicio, {
    auth: true
  }),
  ...categorias,
  ...servicios,
  ...pack,
  ...booking,
  ...paquetes,
  ...ucsis,
  ...post,
  ...location,
  ...admin_post,
  ...reportes,
  ...chat,
  ...productos,
  ...adverts,
  ...ticket,
  ...lead,
  ...directorio,
  ...perfil,
  ...fblead,
  ...costo,
  ...account
]

const router = new VueRouter({
  //mode: 'history',
  routes
})

function route(path, component = 'default', meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

router.beforeEach((to, from, next) => {

  if (to.meta.auth) {
    const user_storage = JSON.parse(localStorage.getItem('user'))

    const user_pack = JSON.parse(localStorage.getItem('package'))

    const authUser = user_storage ? user_storage.role : null
    //console.log('si', (user_storage && (authUser == 'CONSULTANT_ROLE' || authUser == 'HANDYMAN_ROLE') && user_pack != null)));
    if (!auth.authenticated()) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else if (to.meta.require_user) {
      (user_storage && authUser == 'USER_ROLE') ? next(): next('/404')
    } else if (to.meta.require_admin) {
      (user_storage && authUser == 'ADMIN_ROLE') ? next(): next('/404')
    } else if (to.meta.require_consultant_handy_package) {
      (user_storage && (authUser == 'CONSULTANT_ROLE' || authUser == 'HANDYMAN_ROLE') && user_pack != null) ? next(): next('/seleccionar-package')
    } else if (to.meta.require_consultant_package) {
      (user_storage && authUser == 'CONSULTANT_ROLE' && user_pack != null) ? next(): next('/seleccionar-package')
    } else if (to.meta.require_consultant) {
      (user_storage && authUser == 'CONSULTANT_ROLE') ? next(): next('/404')
    } else if (to.meta.require_consultant_handy) {
      (user_storage && authUser == 'CONSULTANT_ROLE' || authUser == 'HANDYMAN_ROLE') ? next(): next('/404')
    } else if (to.meta.require_colaborator) {
      (user_storage && authUser == 'COLLABORATE_ROLE') ? next(): next('/404')
    } else if (to.meta.require_company) {
      (user_storage && authUser == 'COMPANY_ROLE') ? next(): next('/404')
    } else {
      next()
    }
  } else {
    next()
  }
})


export default router