import ListaUsuarios from '../componentes/ListaUsuarios'

const routes = [
  ...route('/lista-usuarios', ListaUsuarios, {
    auth: true,
    require_admin: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes