import axios from 'axios'

export const costo_service = {

  async get_city_prices(data) {
    return axios.get(`costo-vida/list-items-country-temporal`, data)
  },

  async get_cities() {
    return axios.get(`costo-vida/list-city-without-items/all`)
  },

  async update_city(id, data) {
    return axios.put(`costo-vida/city/${id}`, data)
  },

}